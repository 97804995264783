html, body{
    position: relative;
}
body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    cursor: context-menu;
    background-color: var(--primary-dark);
}
.noDisplay{
    display: none!important;
}
:root .darkBg{
    --line:hsla(0,0%,100%,0.11);
}
:root{
    --x:50%;
    --y:50%;
    --line:rgba(0,0,0,0.2);
    --primary-light:#f1f1f1;
    --primary-dark:#0e0e0e;
    --secondary-light:#c2c2c2;
    --highlight-green:#49ffba;
    --highlight-red:#a80e37;
    --highest-zindex:2147483644;
}
a{
    color: inherit;
    text-decoration: none!important;
}
a:hover{
    color: inherit;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}
ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
}
::selection {
    color: var(--primary-light);
    background: var(--highlight-red);
    -webkit-text-fill-color: #ffffff!important;
}
button{
    outline: none!important;
}
.stylishScroll{
    overflow-y: scroll;
    cursor: context-menu;
    line-height: 1.35;
    padding-right: 3px;
    word-spacing: 1.5px;
}
.stylishScroll::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
.stylishScroll::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}
.stylishScroll::-webkit-scrollbar-thumb
{
    background-color: #4e4e66;
}
.noScroll{
    transition: 0.3s;
    overflow:hidden;
}
.lines{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
}
.lines .col{
    border-right: 1px solid var(--line);
}
.phead{
    position: relative;
    margin-left: 16.66%;
    width: auto;
    font-family: 'Lobster', cursive;
    font-size: 25px;
    z-index: 3;
    margin-bottom: 20px;
    color: var(--highlight-red);
}
.phead::after{
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: var(--highlight-red);
    bottom: -2px;
    left: 50%;
    content: '';
    transform: translateX(-50%);
}
.phead::before{
    content: "";
    width: 50px;
    height: 35px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: -1;
    transform: rotate(10deg);
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background:linear-gradient(var(--highlight-red),transparent);
}
.darkBg .phead{
    color: var(--secondary-light);
    background: linear-gradient(134deg, 
    rgba(250,235,215,1) 32%, 
    rgba(252,247,241,1) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.darkBg .phead::after{
    height: 1px;
    background-color: var(--secondary-light);
}
.darkBg .phead::before{
    background:linear-gradient(#ffffff,transparent);
}
#loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background-color: white;
    z-index: 2147483647;
}
#Main{
    width: calc(100% - 60px);
    top: 0;
    left: 0;
    position: relative;
    min-height: 100vh;
    z-index: 2;
}
.logo{
    width: auto;
    height: 80px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
}
.section{
    position: relative;
    min-height: 100vh;
    background-color: #ffffff;
    z-index: 2;
    color: var(--primary-dark);
    font-weight: 500;
}
.section::after{
    position: absolute;
    top: 0;
    right: -60px;
    width: 60px;
    height: 100%;
    background-color: inherit;
    content: '';
}
.darkBg{
    color: var(--secondary-light);
    font-weight: normal;
    background-color: var(--primary-dark);
}
.putBehind{
    z-index: -1!important;
}

.scrollProgress{
    position: fixed;
    left: 11px;
    bottom: 30px;
    margin: 0 12px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;
    transition: 0.3s;
    z-index: 99;
    transform-origin: 100% 100%;
    mix-blend-mode: difference;
}
.Opacity{
    opacity: 0;
}
.gladToHaveYou{
    position: fixed;
    bottom: 100px;
    left: 0;
    height: 60px;
    width: 320px;
    background: rgb(255, 255, 255);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-left: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    transform: translateX(-100%);
    transition: 0.5s;
}
.showGladToHaveYou{
    transform: translateX(0);
}

@media screen and (max-width:580px){
    .lines .col:nth-child(even){
        border-right: none;
    }
    #Main{
        width: calc(100% - 30px);
    }
    .section::after{
        right: -30px;
    }
    html{
        overflow-x: hidden;
    }
    #Sidebar{
        mix-blend-mode: normal;
        backface-visibility: hidden!important;
        -moz-backface-visibility: hidden!important;
        -webkit-backface-visibility: hidden!important;
    }
    .hamBox, .goToTop.active{
        border: 1px solid var(--primary-dark)!important;
    }
}

.cursor-follower.active {
    opacity: 1;
    width: 90px;
    height: 90px;
}
.activeVideo{
    background-color: rgb(255, 13, 13);
    mix-blend-mode: unset;
}
.activeVideo::before{
    content: '';
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 22px solid white;
    border-radius: 2px;
    height: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-27%,-50%);
}

/* <div className="whatsapp">
    <a href="https://twitter.com/messages/compose?recipient_id=1129830842065346560&text=Hello%20world"
        className="twitter-dm-button" data-screen-name="@ayankoley007" target="_blank" rel="noreferrer">
        <img src={msg} alt="Twitter" />
    </a>
</div> */


.pageX{
    position: fixed;
    left: -250px;
    top: -100%;
    width: 2500px;
    height: 300vh;
    transform: rotate(-45deg) translate(-100%);
    transition: 0s;
    transition-delay: 0s;
    opacity: 0;
    z-index: 200000000;
}
@media screen and (max-width:600px){
    .pageX{
        left: -60%;
    }
}
.pageX1{
    background-color: black;
    z-index: 1000;
}
.pageX2{
    background-color: rgb(55, 216, 103);
    z-index: 1002;
}
.pageX3{
    background-color: rgb(209, 19, 19);
    z-index: 1003;
}
.pageX4{
    background-color: rgb(14, 47, 66);
    z-index: 1004;
}
.pageX.come{
    opacity: 1;
    transform: rotate(-45deg) translate(-0%);
}
.pageX1.come{
    transition: 1s;
    transition-delay: 0s;
}
.pageX2.come{
    transition: 0.9s;
    transition-delay: 0.3s;
}
.pageX3.come{
    transition: 0.7s;
    transition-delay: 0.5s;
}
.pageX4.come{
    transition: 0.6s;
    transition-delay: 0.6s;
}
.pageX.go{
    transition: 0s;
    transform-origin: 100% 100%;
    transform: rotate(-45deg) translateY(-200%);
}
.pageX4.go{
    transition: 2s;
}
.hello.cursor-none{
    pointer-events: none;
}
.pageTxt{
    position: absolute;
    width: 300px;
    height: 300px;
    top: 50%;
    left: 40%;
    color: azure;
    font-size: 40px;
}
@media screen and (max-width:800px) {
    .pageTxt{
        top: 30%;
        left: 30%;
    }    
}
@media screen and (max-width:500px) {
    .pageTxt{
        top: 20%;
        left: 25%;
    }    
}