#Contact .social{
    margin: 0 16.66%;
}
#Contact .social ul{
    justify-content: flex-start;
}
#Contact .media a{
    color: var(--primary-dark);
    display: block;
    line-height: 16px;
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    font-size: 25px;
    -webkit-text-stroke: 1px var(--primary-light);
    -webkit-text-fill-color: transparent;
}
#Contact .media a:hover{
    -webkit-text-stroke: 1px transparent;
    -webkit-text-fill-color: var(--primary-light);
}
#Contact .media a:hover::after,
#Contact .media a:hover::before{
    border-color: var(--primary-light);
}
#Contact{
    min-height: 200px;
}
#Contact form{
    margin: 0 16.66%;
}
#Contact form > div{
    position: relative;
    width: 100%;
}
#Contact input{
    background: transparent;
    border: 1px solid rgb(206, 206, 206);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    color: #878795;
    height: 70px;
    margin-bottom: 15px;
}
#Contact textarea{
    background: transparent;
    border: 1px solid rgb(206, 206, 206);
    display: flex;
    resize: none;
    align-items: center;
    box-shadow: none;
    justify-content: center;
    height: 150px;
    margin-bottom: 15px;
    padding: 1rem .75rem;
}
#Contact form button{
    background-color: transparent;
        color: var(--secondary-light);
        border: 1px solid rgb(206, 206, 206);
    width: 180px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
    font-weight: 700;
}
.placeholder{
    position: absolute;
    padding: 0 7px;
    margin-left: 8px;
    z-index: -1;
    top: 50%;
    transition: 0.2s;
    height: 16px;
    line-height: 1;
    transform: translateY(-50%);
    background-color: var(--primary-dark);
}
.msgDiv .placeholder{
    top: 2rem;
}
form input:focus ~ .placeholder, 
form input:valid ~ .placeholder, 
form textarea:focus ~ .placeholder, 
form textarea:valid ~ .placeholder{
    top: 0;
    z-index: 1;
}

.check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
}
.check:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(34,50,84,0.03);
    opacity: 0;
    transition: opacity 0.2s ease;
}
.check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
}
.check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
}
.check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
}
.check:hover:before {
    opacity: 1;
}
.cbx{
    position: absolute;
    width: 0;
    opacity: 0;
    height: 0;
}
.cbx:checked + .check svg {
    stroke: #4285f4;
}
.cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
}
.cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
}
.checkBoxs{
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}
.checkBoxs > div{
    display: flex;
    margin: 0 20px 15px 0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.checkBoxs > div > span{
    transform: translateY(4px);
}
.pointer-events-none{
    pointer-events: none;
}
.hiddenInput{
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}
.swal-button {
    background-color: #239167!important;
}
@media screen and (max-width:520px) {
    .checkBoxs{
        width: 122%!important;
    }
    .checkBoxs > div{
        margin: 0 0 15px 0!important;
    }
}