.project2{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 220px;
    position: relative;
}
.project2 canvas{
    position: absolute;
    border-radius: 5px;
    z-index: 100;
    top: 0;
    left: 0;
}