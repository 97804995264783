.AnimatePresence{
    z-index: 2000;
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.ModalContainer {
    width: 350px;
    height: 310px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CloseButton{
    z-index: 10054;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 18px;
    top: 18px;
    cursor: pointer;
}
.videoCVIf{
    position: relative;
    width: 90%;
    margin-top: 30px;
    border-radius: 5px;
    min-height: 200px;
}
.ModalContainer h6{
    position: absolute;
    top: 18px;
    line-height: 1;
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.downloadBtn{
    text-decoration: underline!important;
    font-weight: normal;
    color: rgb(10, 10, 73);
    cursor: pointer;
}