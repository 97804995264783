.project7{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 320px;
    height: auto;
    border-radius: 10px;
}
.project7 img{
    position: relative;
    height: auto;
    width: 100%;
    z-index: 100;
    border-radius: 10px;
    transition: 0.3s;
}