.magazine {
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.magazine .page {
    height: 100%;
}

.magazine .page img {
    max-width: 100%;
}

.innerpage{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    z-index: 2;
    background-color: honeydew;
    color:black;
    border: 1px solid rgba(0, 0, 0, 0.363);
    box-shadow: 2px 2px 5px black;
}
.sidePage{
    background-color: var(--primary-dark);
    /* background-color: aqua; */
}
.sidePage span{
    display: none;
}
.sidePage.active{
    background-color: white!important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black!important;
    border-radius: 5px;  
}
.sidePage.active span{
    display: block;
    position: absolute;
    font-weight: bold;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 15px;
}
.innerpage > div{
    width: 90%;
    height: 80%;
}
.pageNum{
    position: absolute;bottom: 15px;left: 50%;
    transform: translateX(-50%);
}

/* football */

#ball{
    animation: ball 0.4s ease-out infinite alternate;
}
#left_leg{
    transform: translateY(-10px);
    animation: kick 0.4s ease-out infinite alternate;
    transform-origin: top;
}
#left_hand, #right_hand{
    animation: movement 0.5s ease-in-out infinite alternate; 
}
#short{
    animation: short_movement 0.4s ease-out infinite alternate; 
}
#head, #body{
    animation: body 0.5s ease-in-out infinite alternate;
}
#right_leg{
    animation: body 1s ease-in-out infinite alternate;
}

@keyframes ball{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(-30%);
    }
}

@keyframes kick{
    from{
        transform: rotateZ(0deg);
    }
    to{
        transform: rotateZ(2.5deg);
    }
}

@keyframes movement{
    from{
        transform: rotateZ(1deg);
    }
    to{
        transform: rotateZ(0deg);
    }
}
@keyframes short_movement{
    from{
        transform: rotateZ(0.5deg);
    }
    to{
        transform: rotateZ(0deg);
    }
}

@keyframes body{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(0.5%);
    }
}

/* music */
.music{
    height: 420px;
    width: 420px;
    position: relative;
    zoom: 0.3;
    margin-left: 300px;
    margin-top: 290px;
}
.music .loder{
    flex-direction: row;
    border: 1px solid black;
    border-radius: 50%;
    height: 420px;
    background-color: #eefbff;
    width: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.music .loder > div{
    position: relative;
    width: 40px;
    height: 200px;
    margin: 10px;
    overflow: hidden;
    border-radius: 40px;
    background: linear-gradient(to bottom, 
                rgba(0,0,0,0.05,#edf1f4));
    border: 2px solid #edf1f4;
    box-shadow: 15px 15px 20px rgba(0,0,0,0.1),
                -15px -15px 20px #fff,
                inset -5px -5px 5px rgba(255,255,255,0.05),
                inset 5px 5px 5px rgba(0,0,0,0.05);
}
.music .loder > div::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 15px 15px 20px rgba(0,0,0,0.1),
                -15px -15px 20px #fff,
                inset -5px -5px 5px rgba(255,255,255,0.05),
                inset 5px 5px 5px rgba(0,0,0,0.05);
}
.music .loder > div::after{
    content: '';
    position: absolute;
    left: 2px;
    top: 0;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: inset -5px -5px 5px rgba(0,0,0,0.2),
                0 420px 0 400px #2196f5;
    animation: music 2.5s ease-in-out infinite;
    animation-delay: calc(-0.5s * var(--i));
    overflow: hidden;
}
@keyframes music{
    0%{
        filter: hue-rotate(180deg);
        transform: translateY(160px);
    }
    50%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(160px);
        filter: hue-rotate(0deg);
    }
}
.headphone{
    max-width: 1000px!important;
    position: absolute;
    width: 620px;
    height: auto;
    left: -100px;
    top: -180px;
}

/* diary */
.diary{
    background-color: rgb(216 ,216 ,255);
    border-radius: 25px;
    box-shadow: 10px 10px 10px rgb(27, 27, 27);
    border: 1px solid rgb(27, 27, 27);
    transform: rotate(-11deg);
    z-index: 2;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 210px;
    width: 290px;
    justify-content: center;
}
#DiarySVG{
    transform: rotate(11deg);
}
.page1, .page2, .page3{
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 25px;
    box-shadow: 10px 10px 10px rgb(27, 27, 27);
    top: 0;
    left: 0;
}
.page1{
    transform: translate(10px, 5px);
}
.page2{
    transform: translate(15px, 10px);
}
.page3{
    transform: translate(20px, 15px);
}
.holes{
    position: absolute;
    top: 0;
    left: 5px;
    height: 100%;
    width: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
}
.holes > div{
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 2;
    overflow: hidden;
    border-radius: 50%;
    background: #000;
}
.holes > div::after{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(167, 167, 167);
    z-index: -1;
}
#DiarySVG path.paths:nth-child(2){
    stroke-dasharray: 877;
    stroke-dashoffset: 877;
    animation: line-anime2 10s forwards 0s;
    animation-iteration-count: infinite;
}
#DiarySVG path.paths:nth-child(3){
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: line-anime3 10s forwards 0.4s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(4){
    stroke-dasharray: 310;
    stroke-dashoffset: 310;
    animation: line-anime4 10s forwards 0.8s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(5){
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation: line-anime5 10s forwards 1.2s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(6){
    stroke-dasharray: 429;
    stroke-dashoffset: 429;
    animation: line-anime6 10s forwards 1.6s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(7){
    stroke-dasharray: 1122;
    stroke-dashoffset: 1122;
    animation: line-anime7 10s forwards 2.0s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(8){
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation: line-anime5 10s forwards 2.4s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(9){
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: line-anime3 10s forwards 2.8s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(10){
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation: line-anime5 10s forwards 3.2s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(11){
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: line-anime3 10s forwards 3.6s;
    animation-iteration-count: infinite;    
}
#DiarySVG path.paths:nth-child(12){
    stroke-dasharray: 310;
    stroke-dashoffset: 310;
    animation: line-anime4 10s forwards 4.0s;
    animation-iteration-count: infinite;
}
#DiarySVG path.paths:nth-child(13){
    stroke-dasharray: 475;
    stroke-dashoffset: 475;
    animation: line-anime7 10s forwards 4.4s;
    animation-iteration-count: infinite;
    
}
@keyframes line-anime2{
    0%{
        stroke-dashoffset: 877;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}
@keyframes line-anime3{
    0%{
        stroke-dashoffset: 200;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}
@keyframes line-anime4{
    0%{
        stroke-dashoffset: 310;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}

@keyframes line-anime5{
    0%{
        stroke-dashoffset: 250;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}
@keyframes line-anime6{
    0%{
        stroke-dashoffset: 429;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}
@keyframes line-anime7{
    0%{
        stroke-dashoffset: 475;
    }
    25%,100%{
        stroke-dashoffset: 0;
    }
}
@keyframes line-anime8{
    0%{
        stroke-dashoffset: 1122;
    }
    75%,100%{
        stroke-dashoffset: 0;
    }
}

/* coding */
.coding{
    width: 85%;
    position: relative;
}
.coding .first{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.coding .second{
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.coding  img{
    width: 100px;
    height: auto;
    border-radius: 50%;
}
.coding img.recycle{
    position: absolute;
    left: 50%;
    transform: translate(-50%,-70%);
    width: 70px;
}

/* travel */
.travel{
    width: 300px;
    height: 200px;
}
.travel .imgbox{
    z-index: 2;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    animation: animebg 60s linear infinite;
    transition: 0.5s ease-in-out;
    border-radius: 6px;
    height: 100%;
}
.travel .imgbox .img img{
    object-fit: cover;
    width: 80px;
    height: 35px;
    position: absolute;
    animation: plane 1s ease-in-out infinite alternate;
}
@keyframes animebg{
    0%{
        background-position: 0 0;
    }
    100%{
        background-position: 1600px 0;
    }
}
@keyframes plane{
    from{
        transform: translateY(5px);
    }
    to{
        transform: translateY(-5px);
    }
}

/* stars */
.countingStars{
    width: 100%;
    height: 240px;
    position: relative;
    z-index:4;
}
.countingStars svg{
    position: relative;
    color: black;
    width: 300px;
    height: 250px;
    position: absolute;
}
.countingStars svg path{
    stroke-width: 1;
    stroke: #000200;
    fill: #000200;
}
.stars{
    border-top-right-radius: 25%;
    border-bottom-right-radius: 10px;
    width: 210px;
    left: 40px;
    top: 20px;
    height: 140px;
    background-color: #000200;
    align-items: center;
    justify-content: center;
    position: relative;
}
.star{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    animation: stars 8s ease-in-out infinite;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.star.star1{
    animation-delay: 0s;
    background: url(../../img/hobbies/star1.png);
}
.star.star2{
    animation-delay: -1s;
    background: url(../../img/hobbies/star2.png);
}
.star.star3{
    animation-delay: -2s;
    background: url(../../img/hobbies/star3.png);
}
.star.star4{
    animation-delay: -3s;
    background: url(../../img/hobbies/star4.png);
}
.star.star5{
    animation-delay: -4s;
    background: url(../../img/hobbies/star5.png);
}
.star.star6{
    animation-delay: -5s;
    background: url(../../img/hobbies/star6.png);
}
.star.star7{
    animation-delay: -6s;
    background: url(../../img/hobbies/star7.png);
}
.star.star8{
    animation-delay: -7s;
    background: url(../../img/hobbies/star8.png);
}
@keyframes stars{
    0%,20%,40%,60%,80%,100%{
        opacity: 0;
    }
    10%,30%,50%,70%,90%{
        opacity: 1;
    }
}
.telescope{
    width: 100px;
    position: absolute;
    height: auto;
    bottom: 0;
    left: 0;
}

/* dreaming */
/* <div className='dreaming'>
    <h6 className='dreamingHead'>Dreaming</h6>
    <img 
        src="https://www.transparentpng.com/thumb/thought-bubble/thought-balloon-transparent-pictures-31.png"
        className="thoughtBouble" alt="thought balloon transparent pictures @transparentpng.com" />
    <img src={happy} alt='Dreaming' className='happyKid' />
</div> */
/* .happyKid{
    width: 200px;
    transform: rotate(30deg);
    position: absolute;
    left: 80px;
    top: 150px;
}
.thoughtBouble{
    position: absolute;
    width: 200px;
    transform: rotate(-25deg);
    top: 30px;
    left: 60px;
}
.dreamingHead{
    z-index: 2;
    position: absolute;
    top: 95px;
    left: 100px;
    font-weight: 700;
    font-size: 20px;
    color: #484845;
    transform: rotate(-30deg);
} */

/* books */

.box-out {
    z-index: 2;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 10px;
}
.book {
    width: 130px;
    height: 170px;
    background-color: rgb(62, 71, 152);
    transition: all .3s ease-in-out;
    transform-origin: left center 0px;
    transform-style: preserve-3d;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    background: url(../../img/hobbies/shreekanta.jpg);
    background-size: 100% 100%;
}
.box-out .book::after {
    content: " ";
    display: block;
    opacity: 0;
    width: 130px;
    height: 170px;
    position: relative;
    left: 8px;
    transition: all .3s ease;
}
.box-out .book::before {
    content: " ";
    z-index: 999;
    display: block;
    width: 20px;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: -12px;
    right: 8px;
    transition: all .25s;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAABhCAYAAABh23lYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHCSURBVHgB7dxPTsJAHMXxN7Xu3UhigobxJHACvQFwAr2BcgO8AZxE7uGiDZiQyEIW7oSOUyKbKgLS8u+9b9JNF818fum0XdWUhy9VEDZBGIdBGD6DsFM3bQYgTnjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNZ2hR/7o+UcmnCIsaNCbLcU/RR8oB1bO/4+17l8ixoGeIBBBVvMXI0ih+L7Df2j8iiqnvgh+AVVUXDOTZvF4tNb2qC7DJ1tNgSDO78tblFQxeE92l+0NSjZDjaoMowqyYl7hDF15JzL+x+YHtxLgFq/ZO2m8LT4wsb90nUjmMD61XaRc7ng5+jBua29ntsecq6oIWyELxqdLe8h/GvPp+h0T28D/FezZ8Ip7pHgZt3X5NoPvH1BZ5sNIUTDL7C+6hBWxxt0gk+00tsOe96qH0zL8GO4pBtMg/YhoLMtG8Ii/EpfY4fSoiFk8UeFzpYdwhz/jiNGZ0uHEBjUEzftohJFZyAsdX8BM0/amLC3spYAAAAASUVORK5CYII=');
    background-size: 16px;
    background-repeat: no-repeat;
}
.book:hover {
    cursor: pointer;
    transform: rotateY(-28deg) rotateZ(-2deg) scale(1.02);
    -webkit-backface-visibility: hidden;
    box-shadow: 1px 3px 2px #353d85, 20px 8px 0 #525dc4;
    /* transform: scale(1.02); */
}
.book:hover::after {
    content: " ";
    display: block;
    opacity: 1;
    width: 172px;
    height: 255px;
    position: relative;
    left: 8px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, 0) 60%);
}

.book:hover::before {
    transform: translateY(9px);
    opacity: 1;
}

/* sizing */
.diary{
    zoom: 0.7;
    margin: 80px 0 0 35px;
}
.diary svg{
    zoom: 1.3;
}
.box-out{
    zoom: 0.8;
    float: left;
    margin-right: 15px;
}
.music{
    zoom: 0.2;
    margin: 105px;
    float: right;
}
.footballBg{
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
.countingStars{
    width: 300px;
    zoom: 0.6;
}
.travel{
    zoom: 0.6;
}
.innerpage6 > div{
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.dreaming{
    zoom: 0.7;
    position: relative;
    width: 300px;
    height: 320px;
}
.innerpagetxt h6{
    margin-bottom: 15px;
}
