.zoom      { width: 50px; }
.small img { width: 250px; height: 167px; }
.large img { width: 500px; height: 333px; background: white; }
.az-wrap, .az-small, .az-large {
    position: relative;
}
.az-wrap-inner {
	display: block;
	margin: 0 auto; /* center small & large content */
}
/* This wraps the large image and hides it */
.az-zoom {
	background: #fff;
	border: #333 1px solid;
	position: absolute;
	top: 0;
	left: 0;
	width: 110px;
	height: 110px;
	border-radius: 10px;
	overflow: hidden;

	z-index: 200;
	display: none;
	-moz-box-shadow: inset 0px 0px 4px #000;
	-webkit-box-shadow: inset 0px 0px 4px #000;
	box-shadow: inset 0px 0px 4px #000;
}
/* Class applied to az-mover when large image is windowed */
.az-windowed {
	overflow: hidden;
	position: absolute;
}
/* Class applied to az-mover when large image is fully shown */
.az-expanded {
	height: auto;
	width: auto;
	position: static;
	overflow: visible;
}

/* overlay small area */
.az-overlay {
	background-color: #000;
	opacity: 0.3;
	filter: alpha(opacity=30);
	z-index: 200;
}

/* fade out small content when hovering
.az-hovered > * {
	opacity: 0.5;
	filter: alpha(opacity=50);
}
*/

/* edit mode coordinate styling */
.az-coords {
	display: none; /* hidden when expanded */
}
.az-zoom .az-coords {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	background: #000;
	background: rgba(0,0,0,0.5);
	color: #fff;
}
.project1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
	background: linear-gradient(90deg, rgb(59, 56, 56) 0%, rgba(93,102,105,1) 69%);
}
.project1 img{
	border-radius: 5px;
	z-index: 100;
}
#zoom2{
	z-index: 100;
}

/* @media screen and (max-width:680px){
	.zoom{
		display: none;
	}
} */