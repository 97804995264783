nav{
    position: fixed;
    width: calc(100% - 60px);
    height: 100%;
    background: var(--primary-dark);
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    overflow: hidden;
}
.logo{
    display: flex;
    color: var(--secondary-light);
}
.logo img{
    width: 300px;
    height: 120px;
    position: relative;
}
.logo > div{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: 10px;
}
nav.opened{
    opacity: 1;
    z-index: 1000;
}
.nav1{
    position: relative;
    width: 50%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}
.nav2{
    position: relative;
    width: 50%;
    z-index: 100;
    height: 100%;
    transform: translateX(100%);
    transition: all linear 0.2s;
    transition-delay: 0s;
    color: var(--primary-dark);
    background-color: var(--primary-dark);
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, 0.336);
}
nav.opened .nav2{
    transform: translateX(0%);
    transition: all linear 0.2s;
    transition-delay: 0.3s;
}
.nav2::before{
    content: '';
    background-color:#ffffff;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform-origin: left center;
    transition: transform 0.3s linear;
}
nav.opened .nav2::before{
    transition-delay: 0.7s;
    transform: skew(-10deg) scale(1.3,1);
}
.nav2 .lines{
    z-index: 100;
    border-left: 1px solid var(--line);
}
.nav2 h2, .nav1 h2{
    z-index: 100;
    margin-left: 33.33%;
    padding: 0;
    word-break: break-word;
}
.nav1con{
    width: 100%;
    position: relative;
    margin-left: 33.33%;
    display: flex;
}
.nav1con ul{
    padding-left: 15px;
}
.nav1con ul li{
    height: 50px;
    opacity: 0;
}
.nav1con ul li a{
    font-size: 22px;
    color: var(--primary-light);
}
.nav1con .heading{
    top: 0;
    left: 0;
    position: absolute;
    width: 20px;
    height: 100%;
    transform: translateX(-10px);
}
.nav1con .heading > div{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg) translate(35px, -20px);
}
.nav1con .heading > div::before{
    position: absolute;
    width: 280px;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    height: 5px;
    background-color: var(--primary-light);
    content: '';
    border-radius: 3px;
}
@keyframes navLinkFade{
    from{
        opacity: 0;
        transform: translateX(-100%);
    }
    to{
        opacity: 1;
        transform: translateX(0%);
    }
}
.nav2con{
    width: 200px;
    position: relative;
    margin-left: 33.33%;
    display: flex;
    flex-flow: column;
    z-index: 100;
    height: 300px;
    font-weight: 500;
}
.nav2con .heading{
    top: 0;
    left: 0;
    position: absolute;
    width: 20px;
    height: 100%;
    transform: translateX(-10px);
}
.nav2con .heading > div{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    font-weight: 700;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg) translate(35px, -20px);
}
.nav2con .heading > div::before{
    position: absolute;
    width: 280px;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    height: 6px;
    border-radius: 3px;
    background-color: var(--primary-dark);
    content: '';
}
.nav2con .noticeBg, .nav2con .social{
    padding-left: 15px;
    position: relative;
}
.nav2con .notice{
    position: relative;
    width: 100%;
    height: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: uptodown 6s linear infinite;
}
.nav2con .notice > div{
    margin: 10px;
    position: relative;
    cursor: pointer;
}
.nav2con .notice > div:hover{
    text-decoration: underline;
}
.nav2con .noticeBg{
    overflow: hidden;
}
.noticeHead{
    font-size: 18px;
    text-align: center;
    width: 100%;
    padding-left: 15px;
}
.media{
    padding: 0;
    margin: 0;
}
nav .media a{
    color: var(--primary-dark);
    display: block;
    line-height: 16px;
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    font-size: 25px;
    -webkit-text-stroke: 1px var(--primary-dark);
    -webkit-text-fill-color: transparent;
}
.media a::before, .media a::after{
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: .4s linear;
}
.media a:hover{
    transform: scale(.8);
}
nav .media a:hover{
    -webkit-text-stroke: 1px transparent;
    -webkit-text-fill-color: var(--primary-light);
}
.social-list{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
nav .social-list li.github a:hover{
    -webkit-text-fill-color: var(--primary-dark)!important;
}
nav .social-list li.pac a:hover{
    -webkit-text-fill-color: var(--primary-dark)!important;
}
.social-list li.twitter a:hover{
    -webkit-text-fill-color: #1DA1F2!important;
}
.social-list li.linkedin a:hover{
    background:#0e76a8;
}
.social-list li.gmail a:hover{
    background:#EA4335;
}
.social-list li.xing a:hover{
    background-color: #006567;
}
.media a:hover::before{
    border-left: 2px solid;
    border-right: 2px solid;
    transform: skewX(20deg);
}
.media a:hover::after{
    border-top: 2px solid;
    border-bottom: 2px solid;
    transform: skewY(-20deg);
}
.nav2con iframe{
    border: 1px solid var(--primary-dark);
    background-color: var(--primary-light);
}
@media screen and (max-width:800px){
    .nav1{
        width: 100%;
    }
    .nav2{
        display: none;
    }
}
@media screen and (max-width:580px){
    .nav1con{
        margin-top: 40px;
    }
}